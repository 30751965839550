import { createI18n } from 'vue-i18n'

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
const currency = process.env.VUE_APP_CURRENCY ?? 'EUR'

const defaultFormats = {
  currency: { style: 'currency', currency },
  'currency-compact': { style: 'currency', currency, notation: 'compact', minimumSignificantDigits: 2, minimumFractionDigits: 1, maximumFractionDigits: 2, maximumSignificantDigits: 4 },
  compact: { notation: 'compact', minimumSignificantDigits: 2, minimumFractionDigits: 1, maximumFractionDigits: 2, maximumSignificantDigits: 4  },
  progression: { signDisplay: 'always', style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2},
  'rate': { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 },
  'rate-round': { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 },
  'rate-fix-one': { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 1 },
  'price-value': { style: 'currency', currency, minimumFractionDigits: 2, maximumFractionDigits: 2 },
  'price-value-round': { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 },
  'fix-one': { currency, minimumFractionDigits: 1, maximumFractionDigits: 1 },
}

const numberFormats = {
  fr: defaultFormats,
  en: defaultFormats,
  hu: defaultFormats
}

const i18n = createI18n({
  silentTranslationWarn: true,
  locale: 'en',
  fallbackLocale: 'fr',
  messages: loadLocaleMessages(),
  legacy: false,
  numberFormats
})

export default i18n
